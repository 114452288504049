import React from 'react';
import { Link } from 'react-router-dom';
import Button from './Button';
import './style/Home.css';
import img404 from './style/img/404img.jpg';

const NotFoundPage = () => {
    return (
        <>
            <div className='homemain' style={{ background: `linear-gradient(-90deg, transparent, #265890 40%),url(${img404})`, backgroundSize: `auto 100%` }}>
                <div className='homeleft'>
                    <h1>404</h1>
                    <h2>Page Not Found</h2>
                    <p>Oops! We can't seem to find the page you're looking for.</p>
                    <Link to={`/contact`}>
                        <Button text='Contact Us' />
                    </Link>
                    <Link to={`/`}>
                        <Button text='Go home' />
                    </Link>
                </div>
                <div className='homeright'>
                </div>
            </div>
        </>
    );
};

export default NotFoundPage;
