import React from 'react';
import { Link } from 'react-router-dom';
import SignTips from './SignTips';

const FandQ = () => {
    return (
        <>
            <div className="headertop">
                <Link className="nav-item" to="/">Home</Link>
                <p>{">"}</p>
                <p>Signs FAQs</p>
            </div>
            <div className='faqdiv'>
                <h3>Signs FAQs</h3>
                <div>
                    <h4>What are safety signs for?</h4>
                    <p>Safety signs are displayed in your workplace to warn your employees of the risks they may face if protective measures are not taken.</p>
                </div>
                <div>
                    <h4>What do the colours mean? </h4>
                    <ul>
                        <li><span style={{ color: `red` }}>Red</span> - Danger, Prohibition, Fire, Emergency</li>
                        <li><span style={{ color: `#adb700` }}>Yellow</span> - Warning, Caution, Take Precautions</li>
                        <li><span style={{ color: `green` }}>Green</span> - Safe, Escape, Exit, Equipment</li>
                        <li><span style={{ color: `blue` }}>Blue</span> - Mandatory, Things to do, PPE</li>
                    </ul>
                </div>
                <div>
                    <h4> What do the shapes mean?</h4>
                    <p>The Safety Signs Regulations specify basic shapes to be used for signs. These are sometimes included as pictograms within a larger sign, but in the main, they form the general shape of the sign. The shapes have the following meanings:</p>
                    <ul>
                        <li>Round Circle - mandatory to do</li>
                        <li>Crossed Out - mandatory NOT to do</li>
                        <li>Triangular - warning</li>
                        <li>Oblong - information</li>
                    </ul>
                </div>
                <div>
                    <h4>What do the pictograms mean?</h4>
                    <p>Pictograms are added to make it easier to identify the issue without the need for lengthy explanations. These are mainly standardised by the regulations, however, anything not covered can have a new pictogram invented, provided it is clear and easy to interpret.</p>
                </div>
                <div>
                    <h4>How big should signs be?</h4>
                    <p>All signs are required to be sufficiently large enough to be seen readily from where the information is needed, e.g. a fire exit direction sign may need to be seen from the opposite side of a hall. Signs must be in the line of sight. This is normally eye level for signs where people can get close, and top of door height for signs intended to be seen from a long way away.</p>
                    <p>As a general rule, we use 4 standard sizes:</p>
                    <ul>
                        <li>225 x 300mm - 5 metres viewing distance</li>
                        <li>300 x 400mm - 10 metres viewing distance</li>
                        <li>450 x 600mm - 20 metres viewing distance</li>
                        <li>600 x 800mm - 30 metres viewing distance</li>
                    </ul>
                </div>
            </div>
            <SignTips />
        </>
    )

}

export default FandQ