import React from 'react';
import './style/Materials.css';
import Acrylic from './style/img/Acrylic.jpg';
import Vinyl from './style/img/Vinyl.jpg';
import Fabric from './style/img/Fabric.jpg';
import Foam from './style/img/Foam.jpg';
import Dibond from './style/img/Dibond.jpg';
import Polycarbonate from './style/img/Polycarbonate.jpg';
import Wood from './style/img/Wood.jpg';
import PVC from './style/img/PVC.jpg';
import CorrugatedPlastic from './style/img/CorrugatedPlastic.jpg';
import Aluminum from './style/img/Aluminum.jpg';



const materials = [
    {
      name: 'Acrylic',
      benefits: [
        'Versatile and durable',
        'Ideal for illuminated signs',
        'Suitable for indoor/outdoor applications',
      ],
      image: Acrylic,
    },
    {
      name: 'Aluminum',
      benefits: [
        'Lightweight and rust-resistant',
        'Perfect for outdoor signs',
        'Great for building identification and parking signs',
      ],
      image: Aluminum,
    },
    {
      name: 'Corrugated Plastic (Coroplast)',
      benefits: [
        'Cost-effective and lightweight',
        'Waterproof for outdoor use',
        'Ideal for temporary and yard signs',
      ],
      image: CorrugatedPlastic,
    },
    {
      name: 'PVC (Polyvinyl Chloride)',
      benefits: [
        'Durable and weather-resistant',
        'Suitable for indoor and outdoor signs',
        'Great for display signs and dimensional letters',
      ],
      image: PVC,
    },
    {
      name: 'Vinyl',
      benefits: [
        'Flexible and weather-resistant',
        'Commonly used for banners and window graphics',
        'Ideal for vehicle wraps',
      ],
      image: Vinyl,
    },
    {
      name: 'Wood',
      benefits: [
        'Natural and rustic look',
        'Excellent for carved and sandblasted signs',
        'Great for plaques and outdoor signs',
      ],
      image: Wood,
    },
    {
      name: 'Foam Board',
      benefits: [
        'Lightweight and cost-effective',
        'Ideal for temporary indoor signs',
        'Suitable for presentations and event displays',
      ],
      image: Foam,
    },
    {
      name: 'Aluminium Composite Panel',
      benefits: [
        'Strong, lightweight, and weather-resistant',
        'Composite material with aluminum sheets and polyethylene core',
        'Great for outdoor and indoor signs',
      ],
      image: Dibond,
    },
    {
      name: 'Polycarbonate',
      benefits: [
        'Strong and impact-resistant',
        'Ideal for outdoor illuminated signs',
        'Suitable for vandal-resistant signage and protective covers',
      ],
      image: Polycarbonate,
    },
    {
      name: 'Fabric',
      benefits: [
        'Soft, textured appearance',
        'Ideal for flags, fabric banners, and trade show displays',
        'Enhances visual appeal of your signage',
      ],
      image: Fabric,
    },
  ];
  
  const MaterialCard = ({ material }) => {
    const [showBenefits, setShowBenefits] = React.useState(false);
  
    const toggleBenefits = () => {
      setShowBenefits(!showBenefits);
    };
  
    const hideBenefits = () => {
      setShowBenefits(false);
    };
  
    return (
      <div
        className={`material-card${showBenefits ? ' show-benefits' : ''}`}
        onClick={toggleBenefits}
        onMouseLeave={hideBenefits}
      >
        <h3>{material.name}</h3>
        <ul>
            <li>Advantages: </li>
          {material.benefits.map((benefit, index) => (
            <li key={index}>·{benefit}</li>
          ))}
        </ul>
        <img src={material.image} alt={material.name} />
      </div>
    );
  };
  
  const Materials = () => (
    <div className="materials-display">
      <h2 style={{margin:`70px auto 50px auto`, color:`#024695`}}>Discover Our Materials and Their Benefits</h2>
      <div className="materials-grid">
        {materials.map((material, index) => (
          <MaterialCard key={index} material={material} />
        ))}
      </div>
    </div>
  );
  
  export default Materials;