import React from 'react';
import { Link } from 'react-router-dom';
import ContactForm from './ContactFrom';
const Contact = () => {
    return (
        <>
            <div className="headertop">
                <Link className="nav-item" to="/">Home</Link>
                <p>{">"}</p>
                <p>Contact Us</p>
            </div>
            <div className='faqdiv'>
                <h3>Contact Us</h3>
                <div>
                    <h4>About Us</h4>
                    <p>No matter the size of your workplace, safety signs are vital for ensuring the safety of your employees. Whether you are directing them where to go, restricting certain areas or alerting them to hazards, SOS Safety Signs has all your signage needs covered. We are an Adelaide owned and operated sign manufacturing business, specialising in safety signs and decals, such as Danger Signs, Exit and Evacuation Signs, Mandatory Signs and Direction Signs. </p>
                    <p>We also can manufacture custom made signage to suit any requirement and can provide your business with the "look" you need including everything from a small sign for a reception desk all the way through to creating and installing signage to your full fleet of vehicles...</p>
                    <p>We pride ourselves on quick turn around, quality products and competitive prices all with a friendly smile and a focus on customer service/satisfaction. All manufacture is done in house at our Hampstead Gardens workshop.</p>
                </div>
                <div>
                    <h4>Contact Us</h4>
                    <ContactForm/>
                </div>
                <div>
                    <div>
                        <h4>Our Contact Details</h4>
                        <p>Address:  93 Muller Road, HAMPSTEAD GARDENS SA  5086</p>
                        <p>Pox Box: PO Box 594, GREENACRES  SA  5086</p>
                        <p>Phone: (08) 8266 3600</p>
                        <p>Fax: (08) 8367 6761</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Contact;
