import React, { useState } from 'react';
import { useNavigate as useRouterNavigate } from 'react-router-dom';
import config from "../config.js";


const Login = () => {

  const navigate = useRouterNavigate();

  const [form, setForm] = useState({
    username: '',
    password: '' 
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${config.API_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(form)  
      });
      
      if(response.ok) {
        const data = await response.json();
        localStorage.setItem('token', data.access_token); 
        navigate('/edit');
      } else {
        console.log('Failed');
      }
    } catch(err) {
      console.log(err);  
    }
  };

  return (
    <>
      <div className='login-form'>
        <form onSubmit={handleSubmit}>
          <input 
            placeholder="User Name"
            value={form.username}
            onChange={(e) => setForm({...form, username: e.target.value})}
          />
        
          <input
            type="password" 
            placeholder="Password"
            value={form.password}
            onChange={(e) => setForm({...form, password: e.target.value})}  
          />
        
          <button type="submit">Login</button>
        </form>
      </div>
    </>
  );
}

export default Login;
