import React from 'react';
import Button from './Button';

const SignTips = () => {
    return (
        <div className='tipsdiv'>
            <div>
                <div className='subheader'>
                    <h3>MATERIALS WE USE</h3>
                </div>
                <h4>Our signs can be mounted on:</h4>
                <ul>
                    <li>Colorbond Steel</li>
                    <li>Poly/plastic</li>
                    <li>Corflute</li>
                    <li>Magnetic</li>
                </ul>

                <h4>We can also supply them as:</h4>
                <ul>
                    <li>Self adhesive stickers</li>
                    <li>PVC banners</li>
                </ul>
                <h4>We can also supply them as:</h4>
                <ul>
                    <li>Self adhesive stickers</li>
                    <li>PVC banners</li>
                </ul>
            </div>
            <div>
                <div className='subheader'>
                    <h3>SIZES AVAILABLE</h3>
                </div>
                <h4>We can make signs to any size.</h4>
                <p>Standard sizes are: (mm)</p>
                <ul>
                    <li>100 x 150</li>
                    <li>150 x 200</li>
                    <li>225 x 300</li>
                    <li>250 x 250</li>
                    <li>300 x 400</li>
                    <li>450 x 600</li>
                    <li>600 x 800</li>
                    <li>1000 x 750</li>
                    <li>1200 x 900</li>
                    <li>1200 x 2400</li>
                </ul>
            </div>
            <div>
                <div className='subheader'>
                    <h3>VINYL FEATURES</h3>
                </div>
                <h4>We have a vinyl product to suit your needs.</h4>
                <ul>
                    <li>Digital Vinyl - white only. Used for generic prints.</li>
                    <li>Matt Vinyl - matt finish. Ability to write on with pen.</li>
                    <li>Clear Vinyl - can be used either side of a window.</li>
                    <li>Cast Vinyl - all colours. Used for vinyl cut.</li>
                    <li>Luminous - glows in the dark.</li>
                    <li>Reflective - reflects light at night time.</li>
                </ul>

                <h4>Still have questions? Give us a call on:</h4>
                <p> (08) 8266 3600 </p>
                <h4>More contact details:</h4>
                <a href='/contact'>
                    <Button text='Contact Us' />
                </a>
            </div>
        </div >
    );
};

export default SignTips;
