// src/Signs.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ImageWithPlaceholder from './ImageWithPlaceholder';
import config from '../config';


const Signs = () => {
  const [items, setItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isFilterExpanded, setIsFilterExpanded] = useState(false)
  const itemsPerPage = 25;

  useEffect(() => {
    fetchData();
    fetchCategories();
  }, [currentPage, selectedCategory]);

  const fetchData = async () => {
    const response = await axios.get(`${config.API_URL}/items`, {
      params: {
        page: currentPage,
        itemsPerPage: itemsPerPage,
        category: selectedCategory,
      },
    });
    //console.log(response.data)
    setItems(response.data.items);
    setTotalPages(response.data.total_pages);
  };

  const fetchCategories = async () => {
    const response = await axios.get(`${config.API_URL}/categories`);
    //console.log(response.data)
    setCategories(response.data);
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setCurrentPage(1);
  };

  function formatValue(value) {
    return value
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  return (
    <div>
      <div className="headertop">
        <Link className="nav-item" to="/">Home</Link>
        <p>{">"}</p>
        <p>Signs</p>
        <button
          style={{ fontSize: "13px" }}
          className={`filterbutton ${!selectedCategory ? 'selected' : ''}`}
          onClick={() => setIsFilterExpanded(!isFilterExpanded)}
        >
          Filter
        </button>
      </div>
      <div className="signsdiv">
        <div
          className={isFilterExpanded ? "categories categories_expanded" : "categories"}
          onClick={() => setIsFilterExpanded(!isFilterExpanded)}
        >
          <div
            className={isFilterExpanded ? "categoriescontent expanded" : "categoriescontent"}
            onClick={() => setIsFilterExpanded(!isFilterExpanded)}
          >
            <h3 style={{ color: "#265890" }}>Categroies:</h3>
            <button
              style={{ fontSize: "13px" }}
              className={`btn_small category-button ${!selectedCategory ? 'selected' : ''}`}
              onClick={() => { setIsFilterExpanded(!isFilterExpanded); handleCategoryClick(null); }}
            >
              All categories
            </button>
            {categories.slice(1).map((category) => (
              <button
                style={{ fontSize: "13px" }}
                key={category}
                className={`btn_small category-button ${selectedCategory === category ? 'selected' : ''
                  }`}
                onClick={() => { setIsFilterExpanded(!isFilterExpanded); handleCategoryClick(category); }}
              >
                {formatValue(category)}
              </button>
            ))}
          </div>
        </div>
        <div className="gallerydiv">
          {items.map((item) => (
            <div className="gallery" key={item.id}>
              <Link to={`/signdetails/${item.id}`}>
                <ImageWithPlaceholder
                  imageSrc={`${config.API_URL}${item.image_url}`}
                  placeholderSrc="your-default-image-source.png"
                  alt={`${item.title}`}
                />
              </Link>
              <div className="desc">
                <Link to={`/signdetails/${item.id}`}>{item.title}</Link>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="pagination">
        <button
          className='btn_small'
          disabled={currentPage === 1}
          onClick={() => setCurrentPage((prevPage) => Math.max(1, prevPage - 1))}
        >
          Previous
        </button>
        <span style={{ font: '700 0.75rem/0 "Montserrat", sans-serif' }}>Page {currentPage} of {totalPages}</span>
        <button
          className='btn_small'
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage((prevPage) => Math.min(totalPages, prevPage + 1))}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Signs;