import React, { useState, useContext } from 'react';
import ReactDOMServer from 'react-dom/server'
import axios from 'axios';
import CartBox from './CartBox';
import { Link } from "react-router-dom";
import { SignContext } from "../App";
import config from '../config';
import Ok from './style/img/ok.png';
import Failed from './style/img/failed.png';
import './style/ContactFrom.css';

const ContactForm = () => {
    const [state, setState] = useState({
        name: '',
        email: '',
        message: '',
        phone: '',
        cart: ''
    });
    const [isFormVisible, setFormVisible] = useState(true);
    const [isOkActive, setIsOkActive] = useState(false);
    const [isFailedActive, setIsFailedActive] = useState(false);



    const handleChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const context = useContext(SignContext);


    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormVisible(false);
        try {
            const response = await axios.post(`${config.API_URL}/submitform`, state);
            //console.log(response.data.message);
            setIsOkActive(true)
        } catch (error) {
            //console.error('There was an error!', error);
            setIsFailedActive(true)
        }

    };

    const totalCartCount = context.state.cart.reduce(
        (total, sign) => (total = total + sign.count),
        0
    );

    state.cart = ReactDOMServer.renderToString(
        context.state.cart.map((sign) => (
            <div className="cartItem" key={sign.id} style={{
                display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', width: '80%', 
                margin: 'auto', marginBottom: '1em', padding: '1em', backgroundColor: '#efefef'
            }}>
                <a href={`https://sossafetysigns.com.au/signdetails/${sign.id}`} style={{ width: '25%' }}>
                    <div className="imgDiv" style={{ display: 'flex', justifyContent: 'center', margin: 'auto' }}>
                        <img src={`${config.API_URL}${sign.image_url}`} alt={`${sign.title}`} style={{ height: '100px', margin: 'auto' }} />
                    </div>
                </a>
        
                <div className="cartItemRight" style={{ width: '70%', padding: '0.5em' }}>
                    <div className="cartItemUpper" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                        <h4 style={{ textAlign: 'center', margin: '0.5em 0 0 0' }}>{sign.title}</h4>
                        <div className="cartItemBtn" style={{ display: 'flex' }}>
                            <p style={{ margin: '0.5em 0' }}>Quantity: {sign.count}</p>
                            <div style={{ margin: '0.5em 1em' }}>
                                <button onClick={() => context.decrease(sign.id)}>-</button>
                                <button onClick={() => context.removeFromCart(sign.id)}>Remove</button>
                                <button onClick={() => context.increase(sign.id)}>+</button>
                            </div>
                        </div>
                    </div>
                    <div className="cartItemLower">
                        <div className="cartItemLowerSub" style={{ margin: '0.5em 0', display: 'flex' }}>
                            <p style={{ margin: '0 1em 0 0' }}>Size: {sign.size}</p>
                            <p style={{ margin: '0 1em 0 0' }}>Material: {sign.material}</p>
                        </div>
                        <p style={{ margin: '0' }}>Note: {sign.note}</p>
                    </div>
                </div>
            </div>
        ))
    );
    
    if(totalCartCount == 0){
        return (
            <div style={{position:"relative"}}>
                <div className={`contactFormOutter ${!isFormVisible ? 'hidden' : ''}`}>
                    <form onSubmit={handleSubmit}>
                        <div className='contactForm'>
                            <div className='formItem'>
                                <p>Your Name</p>
                                <input
                                    type="text"
                                    name="name"
                                    value={state.name}
                                    onChange={handleChange}
                                    placeholder="Your name"
                                />
                            </div>
                            <div className='formItem'>
                                <p>Your Contact</p>
                                <input
                                    type="phone"
                                    name="phone"
                                    value={state.phone}
                                    onChange={handleChange}
                                    placeholder="Your phone"
                                />
                            </div>
                            <div className='formItem'>
                                <p>Your Email</p>
                                <input
                                    type="email"
                                    name="email"
                                    value={state.email}
                                    onChange={handleChange}
                                    placeholder="Your email"
                                />
                            </div>
                            <div className='formItem'>
                                <p>Your Message</p>   
                                <textarea
                                    name="message"
                                    value={state.message}
                                    onChange={handleChange}
                                    placeholder="Message"
                                />
                            </div>
                            <div className='formItem'>
                                <p>Cart items: 0</p>   
                                <p>Your cart is empty, you can add products from our <Link to={`/signs/`}>signs</Link> or<Link to={`/customersigns/`}>customer signs</Link> page to the cart to get a free qoute!</p>
                                
                            </div>
                            <button type="submit" className='btn_general'>Submit</button>
                        </div>
        
                    </form>
                </div>
                <div className={`okResponse fade-in ${isOkActive ? 'active' : ''}`}>
                    <img src={Ok} alt="OK" style={{ height: '100px', margin: 'auto' }} />
                    <p>Thank you for your message, we will get back to you as soon as we can!</p>
                </div>
                <div className={`failedResponse fade-in ${isFailedActive ? 'active' : ''}`}>
                    <img src={Failed} alt="OK" style={{ height: '100px', margin: 'auto' }} />
                    <p>Oh, there must be something wrong, please give us a call on (08) 8266 3600</p>
                </div>
            </div>
        );
    }
    else{
        return (
            <div style={{position:"relative"}}>
                <div className={`contactFormOutter ${!isFormVisible ? 'hidden' : ''}`}>
                    <form onSubmit={handleSubmit}>
                        <div className='contactForm'>
                            <div className='formItem'>
                                <p>Your Name</p>
                                <input
                                    type="text"
                                    name="name"
                                    value={state.name}
                                    onChange={handleChange}
                                    placeholder="Your name"
                                />
                            </div>
                            <div className='formItem'>
                                <p>Your Contact</p>
                                <input
                                    type="phone"
                                    name="phone"
                                    value={state.phone}
                                    onChange={handleChange}
                                    placeholder="Your phone"
                                />
                            </div>
                            <div className='formItem'>
                                <p>Your Email</p>
                                <input
                                    type="email"
                                    name="email"
                                    value={state.email}
                                    onChange={handleChange}
                                    placeholder="Your email"
                                />
                            </div>
                            <div className='formItem'>
                                <p>Your Message</p>   
                                <textarea
                                    name="message"
                                    value={state.message}
                                    onChange={handleChange}
                                    placeholder="Message"
                                />
                            </div>
                            <div className='formItem'>
                                <p>Cart items:</p>   
                                <CartBox />
                                <textarea
                                    style={{display: "none"}}
                                    name="cart"
                                    value={state.cart}
                                    onChange={handleChange}
                                    placeholder='Cart Details'
                                />
                            </div>
                            <button type="submit" className='btn_general'>Submit</button>
                        </div>
        
                    </form>
                </div>
                <div className={`okResponse fade-in ${isOkActive ? 'active' : ''}`}>
                    <img src={Ok} alt="OK" style={{ height: '100px', margin: 'auto' }} />
                    <p>Thank you for your message, we will get back to you as soon as we can!</p>
                </div>
                <div className={`failedResponse fade-in ${isFailedActive ? 'active' : ''}`}>
                    <img src={Failed} alt="OK" style={{ height: '100px', margin: 'auto' }} />
                    <p>Oh, there must be something wrong, please give us a call on (08) 8266 3600</p>
                </div>
            </div>
        );
    }

    
};

export default ContactForm;