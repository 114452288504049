import React from 'react';
import { Link } from 'react-router-dom';
import './style/Footer.css';
import logoimg from './style/img/logo.png';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section footer-branding">
          <img src={logoimg} className="footer-logo" alt="logo" />
          <h2>More Than Just Safety Signs</h2>
          <p>SOS Safety Signs</p>
        </div>
        <div className="footer-section footer-structure">
          <h3>Site Structure</h3>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/signs">Safety Signs</Link>
            </li>
            <li>
              <Link to="/customsigns">Custom Signs</Link>
            </li>
            <li>
              <Link to="/fandq">F&Q</Link>
            </li>
            <li>
              <Link to="/contact">Contact Us</Link>
            </li>
          </ul>
        </div>
        <div className="footer-section footer-contact">
          <h3>Contact Us</h3>
          <p>Pox Box: PO Box 594, GREENACRES SA 5086</p>
          <p>Phone: (08) 8266 3600</p>
          <p>Fax: (08) 8367 6761</p>
          <p>93 Muller Road, HAMPSTEAD GARDENS SA 5086</p>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} SOS Safety Signs. All rights reserved.</p> <Link to="/login">· Manage ·</Link>
      </div>
    </footer>
  );
};

export default Footer;