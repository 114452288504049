// src/Signs.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ImageWithPlaceholder from './ImageWithPlaceholder';
import config from "../config.js";

const token = localStorage.getItem('token');


const EditSigns = () => {
	const [items, setItems] = useState([]);
	const [categories, setCategories] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [isFilterExpanded, setIsFilterExpanded] = useState(false)
	const itemsPerPage = 24;

    const [image, setImage] = useState(null);

	useEffect(() => {
		fetchData();
		fetchCategories();
	}, [currentPage, selectedCategory]);

	const fetchData = async () => {

		const response = await axios.get(`${config.API_URL}/manageitems`, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            },
			params: {
				page: currentPage,
				itemsPerPage: itemsPerPage,
				category: selectedCategory,
			},
		});
		console.log(response.data)
		setItems(response.data.items);
		setTotalPages(response.data.total_pages);
	};

	const fetchCategories = async () => {
		const response = await axios.get(`${config.API_URL}/categories`);
		//console.log(response.data)
		setCategories(response.data);
	};

	const handleCategoryClick = (category) => {
		setSelectedCategory(category);
		setCurrentPage(1);
	};


	function formatValue(value) {
		return value
			.split('-')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	}

    const [dialog, setDialog] = useState({ show: false, message: '', error: false });

    function DialogBox({ message, onClose }) {
        useEffect(() => {
          const timer = setTimeout(onClose, 1000);
          return () => clearTimeout(timer);
        }, [onClose]);
      
        return (
          <div className={`dialog-box ${dialog.error ? 'error' : 'success'}`} onClick={onClose}>
            {message}
          </div>
        );
      }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('id', e.target.querySelector('input[name="id"]').defaultValue);
        formData.append('title', e.target.querySelector('textarea[name="title"]').value);
        formData.append('category', e.target.querySelector('textarea[name="category"]').value);
        formData.append('description', e.target.querySelector('textarea[name="description"]').value);
        if (image) {
            formData.append('image', image);
        }
        else{

        };
        formData.append('mainCategory', e.target.querySelector('textarea[name="main_category"]').defaultValue);
        formData.append('display', e.target.querySelector('textarea[name="display"]').defaultValue);
        console.log(formData)

        try {
            const response = await axios.post(`${config.API_URL}/items`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            },
            });
            if (response.status === 201) {
                setDialog({ show: true, message: 'Update successful', error: false });
            }
        } catch (error) {
            setDialog({
                show: true,
                message: `Update failed: ${error.response.statusText}`,
                error: true
            });
        }

        setImage(null);
    };


    async function deleteItem(itemId) {
        try {
            const response = await fetch(`${config.API_URL}/items/${itemId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
    
            if (response.status === 201) {
                setDialog({ show: true, message: 'Update successful', error: false });
            }    
        } catch (error) {
            setDialog({
                show: true,
                message: `Update failed: ${error.response.statusText}`,
                error: true
            });
        }
    }
        
        

    function ImagePopuup({item, onClose}){
        return (
            <div className='image-popup' onClick={onClose}>
                <ImageWithPlaceholder
            imageSrc={`${config.API_URL}${item.image_url}`}
            placeholderSrc="your-default-image-source.png"
            alt={`${item.title}`}
    />
        <p>Click Image To Close</p>
        </div>
        )
    }

    function ItemRow({item}) {
        const [open, setOpen] = useState(false);
        const handleOpen = () => {
            setOpen(true);
        }
        const handleClose = () => {
            setOpen(false);
        }

        return (
            <>
                {item.image_url}

                <button 
                    onClick={(e) => {
                        e.preventDefault();
                        handleOpen();
                    }}
                >
                    Show Image
                </button>

                {open &&
                    <ImagePopuup
                        item={item}
                        onClose={handleClose}
                    />
                }
            </>
        )
    }


	return (
		<div>
            {dialog.show && (
                <div className="dialog-overlay" onClick={() => setDialog({ ...dialog, show: false })}>
                    <DialogBox
                    message={dialog.message}
                    onClose={() => setDialog({ ...dialog, show: false })}
                    />
                </div>
            )}
			<div className="headertop">
				<Link className="nav-item" to="/">Home</Link>
				<p>{">"}</p>
				<p>Edit Signs (Protected)</p>
				<button
					style={{ fontSize: "13px" }}
					className={`filterbutton ${!selectedCategory ? 'selected' : ''}`}
					onClick={() => setIsFilterExpanded(!isFilterExpanded)}
				>
					Filter
				</button>
			</div>
            <div
					className={isFilterExpanded ? "categories categories_expanded" : "categories"}
                    style={{ width: 'auto' }}
					onClick={() => setIsFilterExpanded(!isFilterExpanded)}
				>
					<div
						className={isFilterExpanded ? "categoriescontent expanded" : "categoriescontent"}
						onClick={() => setIsFilterExpanded(!isFilterExpanded)}
					>
						<h3 style={{ color: "#265890" }}>Categroies:</h3>
						<button
							style={{ fontSize: "13px" }}
							className={`btn_small category-button ${!selectedCategory ? 'selected' : ''}`}
							onClick={() => { setIsFilterExpanded(!isFilterExpanded); handleCategoryClick(null); }}
						>
							All categories
						</button>
						{categories.slice(1).map((category) => (
							<button
								style={{ fontSize: "13px" }}
								key={category}
								className={`btn_small category-button ${selectedCategory === category ? 'selected' : ''
									}`}
								onClick={() => { setIsFilterExpanded(!isFilterExpanded); handleCategoryClick(category); }}
							>
								{formatValue(category)}
							</button>
						))}
					</div>
				</div>
			<div className="signsdiv">
				

					<div className="table">
                    <h3>Add New Item</h3>
					<div className="row header">
						<div className="cell">ID (Do not Change)</div>
						<div className="cell">Title</div>
						<div className="cell">Category</div>
						<div className="cell">Description</div>
						<div className="cell">Main Category</div>
                        <div className="cell">Display ?</div>
						<div className="cell">Image Url</div>
						<div className="cell">Update</div>
					</div>
                    
                    <div key="newitem" className="row">
							<form className='row' onSubmit={handleSubmit} style={{ width: "100%" }}>
                                <div className="cell">
                                <input 
                                    key="newitemId"
                                    name="id"
                                    defaultValue="-1"
                                    readOnly
                                />  
                                </div>

                                <div className="cell">
                                    <textarea 
                                        key="newitemTitle"
                                        name="title"
                                        defaultValue="New Item Here"
                                        required
                                    />  
                                </div>
                                <div className="cell">
                                    <textarea 
                                        key="newitemCategory"
                                        name="category"
                                        defaultValue=""
                                        required
                                    /> 
                                </div>
                                <div className="cell">
                                    <textarea 
                                        key="newitemDescription"
                                        name="description"
                                        defaultValue=""
                                        required
                                    /> 
                                </div>
                                <div className="cell">
                                    <textarea 
                                        key="newitemMain_category"
                                        name="main_category"
                                        defaultValue=""
                                        required
                                    /> 
                                </div>
                                <div className="cell">
                                    <textarea 
                                        key="newitemDisplay"
                                        name="display"
                                        defaultValue=""
                                        required
                                    /> 
                                </div>
                                <div className="cell">
									<input 
                                        type="file" 
                                        onChange={(e) => setImage(e.target.files[0])} 
                                        required
                                    />
                                </div>
                                <div className="cell">
                                    <button type="submit">Update</button>
                                </div>
							</form>
						</div>

                    <h3>Edit Item</h3>
					<div className="row header">
						<div className="cell">ID (Read Only)</div>
						<div className="cell">Title</div>
						<div className="cell">Category</div>
						<div className="cell">Description</div>
						<div className="cell">Main Category</div>
						<div className="cell">Display ?</div>
						<div className="cell">Image Url</div>
						<div className="cell">Update</div>
					</div>

					{items.map(item => (
						<div key={item.id} className="row">


							<form className='row' onSubmit={handleSubmit}>
                                <div className="cell">
                                <input 
                                    key={item.id}
                                    name="id"
                                    defaultValue={item.id}
                                    readOnly
                                />  
                                </div>

                                <div className="cell">
                                    <textarea 
                                        key={item.title}
                                        name="title"
                                        defaultValue={item.title}
                                        // required
                                    />  
                                </div>
                                <div className="cell">
                                    <textarea 
                                        key={item.category}
                                        name="category"
                                        defaultValue={item.category}
                                        // required
                                    /> 
                                </div>
                                <div className="cell">
                                    <textarea 
                                        key={item.description}
                                        name="description"
                                        defaultValue={item.description}
                                        // required
                                    /> 
                                </div>
                                <div className="cell">
                                    <textarea 
                                        key={item.main_category}
                                        name="main_category"
                                        defaultValue={item.main_category}
                                        // required
                                    /> 
                                </div>
                                <div className="cell">
                                    <textarea 
                                        key={item.display}
                                        name="display"
                                        defaultValue={item.display}
                                        // required
                                    /> 
                                </div>
                                <div className="cell">
                                    <ItemRow item={item}/>
									<input 
                                        type="file" 
                                        onChange={(e) => setImage(e.target.files[0])} 
                                        // required
                                    />
                                </div>
                                <div className="cell">
                                    <button type="submit">Update</button>
									<button onClick={(e) => {
                                        e.preventDefault();
                                        deleteItem(item.id);
                                    }}>
                                        Delete
                                    </button>
                                </div>
							</form>
						</div>
					))}

				</div>
			</div>
			<div className="pagination">
				<button
					className='btn_small'
					disabled={currentPage === 1}
					onClick={() => setCurrentPage((prevPage) => Math.max(1, prevPage - 1))}
				>
					Previous
				</button>
				<span style={{ font: '700 0.75rem/0 "Montserrat", sans-serif' }}>Page {currentPage} of {totalPages}</span>
				<button
					className='btn_small'
					disabled={currentPage === totalPages}
					onClick={() => setCurrentPage((prevPage) => Math.min(totalPages, prevPage + 1))}
				>
					Next
				</button>
			</div>
		</div>
	);
};

export default EditSigns;
