import React, { useState, useEffect } from 'react';
import default_img from './style/img/dafult_img.jpg';

function ImageWithPlaceholder({ imageSrc, placeholderSrc, alt }) {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const image = new Image();
    image.src = imageSrc;
    image.onload = () => setImageLoaded(true);
  }, [imageSrc]);

  return (
    <div
      className="imgdiv"
      style={{
        backgroundImage: imageLoaded ? 'none' : `url(${default_img})`,
      }}
    >
      <img
        src={imageSrc}
        alt={alt}
        style={{
          opacity: imageLoaded ? 1 : 0,
        }}
      />
    </div>
  );
}

export default ImageWithPlaceholder;