import React from 'react';
import { createContext, useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import "./App.css";
import SoftScroll from './components/SoftScroll';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Signs from './components/Signs';
import CustomSigns from './components/CustomSigns';
import SignDetails from './components/SignDetails';
import FandQ from './components/FandQ';
import AddSign from './components/AddSign';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Login from './components/Login'; 
import Logout from './components/Logout'; 
// import Register from './components/Register';
import EditSigns from './components/EditSigns';
import Cart from './components/Cart';
import ContactForm from './components/ContactFrom';
import NotFoundPage from './components/NotFoundPage'; 

function getToken() {
  const userToken = localStorage.getItem('token');
  return userToken && userToken
}

const token = getToken();

const ProtectedRoute = ({ token, children }) => {

  if (!token) {
    return <Navigate to="/" replace />;
  }
  return children;
};

export const SignContext = createContext()


function App() {
  const [state, setState] = useState({
    cart: []
  });

  const addToCart = (sign, size, material, note, qty) => {
    const existingItem = state.cart.find(
      (cartItem) =>
        cartItem.id === sign.id &&
        cartItem.size === size &&
        cartItem.material === material &&
        cartItem.note === note
    );

  
    const updatedCart = existingItem
      ? state.cart.map((cartItem) =>
          cartItem.id === sign.id &&
          cartItem.size === size &&
          cartItem.material === material &&
          cartItem.note === note
            ? { ...cartItem, count: cartItem.count + qty }
            : cartItem
        )
      : [...state.cart, { ...sign, size, material, note, count: qty }];
  
    // 更新状态
    setState((prevState) => ({
      ...prevState,
      cart: updatedCart
    }));
  };

  // const addToCart = (sign, size, material, note, qty) => {
  //   setState({
  //     ...state,
  //     cart: state.cart.find((cartItem) => cartItem.id === sign.id)
  //       ? state.cart.map((cartItem) =>
  //           cartItem.id === sign.id
  //             ? { ...cartItem, count: cartItem.count + 1 }
  //             : cartItem
  //         )
  //       : [...state.cart, { ...sign, count: 1 }]
  //   });
  // };

  const removeFromCart = (id) =>
    setState({
      ...state,
      cart: state.cart.filter((cartItem) => cartItem.id !== id)
    });

  const increase = (id) => {
    setState({
      ...state,
      cart: state.cart.map((cartItem) =>
        cartItem.id === id
          ? { ...cartItem, count: cartItem.count + 1 }
          : cartItem
      )
    });
  };

  const decrease = (id) => {
    setState({
      ...state,
      cart: state.cart.map((cartItem) =>
        cartItem.id === id
          ? { ...cartItem, count: cartItem.count > 1 ? cartItem.count - 1 : 1 }
          : cartItem
      )
    });
  };


  return (
    <SignContext.Provider
      value={{ state, addToCart, increase, decrease, removeFromCart }}
    >
      <BrowserRouter>
        <div className="App">
          <Navbar />
          <div className="container">
            <div className="content">
              <SoftScroll />
              <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/signs' element={<Signs />} />
                <Route path='/customsigns' element={<CustomSigns />} />
                <Route path='/fandq' element={<FandQ />} />
                <Route path='/contact' element={<Contact />} />
                <Route path='/addsign' element={<AddSign />} />
                <Route path="/signdetails/:id" element={<SignDetails />} />
                <Route path='/login' element={<Login />} />
                <Route path='/logout' element={<Logout />} />
                <Route path='/cart' element={<Cart />} />
                <Route path='/contactform' element={<ContactForm/>} />
                {/* <Route path='/register' element={<Register />} />  */}
                <Route  
                  path="/edit"
                  element={
                    <ProtectedRoute token = {token}>
                      <EditSigns />
                    </ProtectedRoute>
                  }
                />
                <Route path='*' element={<NotFoundPage />}/>
            </Routes>
            </div>
            <Footer />
          </div>
        </div>
      </BrowserRouter >
    </SignContext.Provider>
  );
}

export default App;