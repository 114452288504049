import React, { useContext } from "react";
import './style/Cart.css';
import { Link } from "react-router-dom";
import { SignContext } from "../App";
import Button from "./Button";
import CartBox from "./CartBox";
import emptyCart  from "./style/img/emptyCart.png";

const Cart = () => {

//   const totalCartAmount = context.state.cart
//     .reduce((total, sign) => (total = total + sign.price * sign.count), 0)
//     .toFixed(2);

    const context = useContext(SignContext);

    const totalCartCount = context.state.cart.reduce(
        (total, sign) => (total = total + sign.count),
        0
    );
    if(totalCartCount == 0){
        return(
            <div>
                <div className="headertop">
                <Link className="nav-item" to="/">Home</Link>
                <p>{">"}</p>
                <p>Cart - ({totalCartCount})</p>
            </div>
            <div className="emptyCart">
                <img src={emptyCart} className="img" alt="Empty Cart" />
                <p>Your cart is empty, you can:</p>
                <a href='/signs'>
                    <Button text='Browse our products' />
                </a>
                <a href='/contact'>
                    <Button text='Contact Us' />
                </a>
            </div>
            </div>
        );
    }

    else{
        return (
            <div style={{minHeight: "90vh"}}>
                <div className="headertop">
                    <Link className="nav-item" to="/">Home</Link>
                    <p>{">"}</p>
                    <p>Cart - ({totalCartCount})</p>
                </div>
                <div className="headertop">
                    <h2>Total qantity: ({totalCartCount})</h2>
                </div>
                <CartBox />
            </div>
        );
    }


  
    
};

export default Cart;
