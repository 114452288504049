import React from 'react';
import { Link } from 'react-router-dom';
import Button from './Button';
import Signs from './Signs';
import './style/Home.css';
import homeimg from './style/img/home.jpg';

const Home = () => {
    return (
        <>
            <div className='homemain' style={{ background: `linear-gradient(-90deg, transparent, #265890 40%),url(${homeimg})`, backgroundSize: `auto 100%` }}>
                <div className='homeleft'>
                    <h1>Welcome to </h1>
                    <h1>SOS Safety Signs</h1>
                    <p>We pride ourselves on quick turn around, quality products and competitive prices all with a friendly smile and a focus on customer service/satisfaction. All manufacture is done in house at our Hampstead Gardens workshop.</p>
                    <p>So, if you're looking for safety signs in Adelaide or any signs for that matter, we've got you covered.</p>
                    <Link to={`/contact`}>
                        <Button text='Contact Us' />
                    </Link>
                    <Link to={`/signs`}>
                        <Button text='View More' />
                    </Link>
                </div>
                <div className='homeright'>
                </div>
            </div>
            <Signs />
        </>
    );
};

export default Home;
