import React, { useState, useEffect, useContext } from 'react';
import { useParams, Link } from "react-router-dom"
import SignTips from './SignTips';
import Button from './Button';
import axios from 'axios';
import { SignContext } from "../App";
import Materials from "./Material";
import config from "../config.js";


const SignDetails = () => {
	const context = useContext(SignContext);
	const { id } = useParams();
	const [item, setItem] = useState(null);
    const [qty, setQty] = useState(1);
    const [size, setSize] = useState('100 x 150'); 
    const [material, setMaterial] = useState('Acrylic'); 
    const [note, setNote] = useState('');

	useEffect(() => {
		const fetchItem = async () => {
			const response = await axios.get(`${config.API_URL}/items/${id}`);
			setItem(response.data.item);
		};

		fetchItem();
	}, [id]);

	if (!item) {
		return <div>Loading...</div>;
	}

	return (
		<>
			<div className='item'>
				<div className='headertop'>
					< Link className="nav-item" to="/">Home</Link>
					<p>{">"}</p>
					< Link className="nav-item" to="/signs">Signs</Link>
					<p>{">"}</p>
					<p>{item.title}</p>
				</div>
				<div className='itemdiv'>
					<div className='itemleft'>
						<img src={`${config.API_URL}${item.image_url}`} alt={`${item.title}`} />
					</div>
					<div className='itemright'>
						<h2>{item.title}</h2>
						<p>Category: <a href={`/signs?category=${encodeURIComponent(item.category)}`}>{item.category}</a></p>
						<p>{item.description}</p>
						{/* <h4>Available Sizes (mm):</h4>
						<ul>
							<li>100 x 150</li>
							<li>150 x 200</li>
							<li>225 x 300</li>
							<li>250 x 250</li>
							<li>300 x 400</li>
							<li>450 x 600</li>
							<li>600 x 800</li>
							<li>1000 x 750</li>
							<li>1200 x 900</li>
							<li>1200 x 2400</li>
						</ul> */}
                        {<div className='addtocartdiv'>
                            <h4>Add it to the cart</h4>
                            <p>You can send us all items from the cart all together to get the qoute!</p>
                            <div className='addtocartitem'>
                                <span>Qantity:</span>
                                <input value={qty} onChange={(e) => setQty(e.target.value)} />
                            </div>
                            <div className='addtocartitem'>
                                <span>Size:</span>
                                <select value={size} onChange={(e) => setSize(e.target.value)}>
                                    <option value="100x150">100 x 150</option>
                                    <option value="150x200">150 x 200</option>
                                    <option value="225x300">225 x 300</option>
                                    <option value="250x250">250 x 250</option>
                                    <option value="300x400">300 x 400</option>
                                    <option value="450x600">450 x 600</option>
                                    <option value="600x800">600 x 800</option>
                                    <option value="1000x750">1000 x 750</option>
                                    <option value="1200x900">1200 x 900</option>
                                    <option value="1200x2400">1200 x 2400</option>
                                    <option value="other">Other, please leave a note</option>
                                </select>
                            </div>
							<div className='addtocartitem'>
                                <span>Material:</span>
                                <select value={material} onChange={(e) => setMaterial(e.target.value)}>
                                    <option value="Acrylic">Acrylic</option>
                                    <option value="Aluminum">Aluminum</option>
                                    <option value="Coroplast">Coroplast</option>
                                    <option value="PVC">PVC</option>
                                    <option value="Vinyl">Vinyl</option>
									<option value="Wood">Wood</option>
                                    <option value="Foam Board">Foam Board</option>
                                    <option value="Aluminium Composite Panel">Aluminium Composite Panel</option>
                                    <option value="Polycarbonate">Polycarbonate</option>
                                    <option value="Fabric">Fabric</option>


                                    <option value="other">Other, please leave a note</option>
                                </select>
                            </div>
                            <div className='addtocartitem'>
                                <span>Note:</span>
                                <textarea value={note} onChange={(e) => setNote(e.target.value)} />
                            </div>
                            <div className='addtocartitem'>
								<button onClick={() => context.addToCart(item, size, material, note, parseInt(qty))}>Add</button>
                            </div>
							

                        </div>}
					</div>
				</div>
			</div>
		<Materials />
		<SignTips />
	</>
	);
};

export default SignDetails;
