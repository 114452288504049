import React, { useState } from 'react';
import axios from 'axios';

const AddSign = () => {
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('');
  const [description, setDescription] = useState('');
  const [mainCategory, setMainCategory] = useState('');
  const [image, setImage] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('title', title);
    formData.append('category', category);
    formData.append('description', description);
    if (image) {
      formData.append('image', image);
    };
    formData.append('mainCategory', mainCategory);

    await axios.post('http://localhost:5000/items', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    setTitle('');
    setDescription('');
    setMainCategory('');
    setImage(null);
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Title:
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
      </label>
      <label>
        Category:
        <input
          type="text"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          required
        />
      </label>
      <label>
        Description:
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
        />
      </label>
      <label>
        MainCategory (safety-signs or customer-signs):
        <textarea
          value={mainCategory}
          onChange={(e) => setMainCategory(e.target.value)}
          required
        />
      </label>
      <label>
        Image:
        <input type="file" onChange={(e) => setImage(e.target.files[0])} />
      </label>
      <button type="submit">Create Item</button>
    </form>

  );
};

export default AddSign;