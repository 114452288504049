import React from 'react';
import { useState, useContext } from "react"
import { NavLink } from 'react-router-dom';
import './style/Navbar.css';
import menuimg from './style/img/menu.png';
import logoimg from './style/img/logo.png';
import { SignContext } from "../App";




export default function Navbar() {
  const [isNavExpanded, setIsNavExpanded] = useState(false)
  function getToken() {
    const userToken = localStorage.getItem('token');
    return userToken && userToken
  }

  const context = useContext(SignContext);

  const totalCartCount = context.state.cart.reduce(
      (total, sign) => (total = total + sign.count),
      0
  );  
  
  const token = getToken();
  return (
    <>
      <nav className="navigation">
        <a href="/" className="brand-name">
          <img src={logoimg} className="logo" alt="logo" />
        </a>
        <div
          className="menuimg"
          onClick={() => {
            setIsNavExpanded(!isNavExpanded)
          }}
        >
          <img src={menuimg} className="logo" alt="logo" />
        </div>
        <div
          className={
            isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
          }
        >
          <ul>
            <li>
              <NavLink className="nav-item" onClick={() => {
                setIsNavExpanded(!isNavExpanded)
              }} exact="true" to="/">Home</NavLink>
            </li>
            <li>
              <NavLink className="nav-item" onClick={() => {
                setIsNavExpanded(!isNavExpanded)
              }} to="/signs">Signs</NavLink>
            </li><li>
              <NavLink className="nav-item" onClick={() => {
                setIsNavExpanded(!isNavExpanded)
              }} to="/customsigns">Custom Signs</NavLink>
            </li>
            <li>
              <NavLink className="nav-item" onClick={() => {
                setIsNavExpanded(!isNavExpanded)
              }} to="/fandq">F&Q</NavLink>
            </li>
            <li>
              <NavLink className="nav-item" onClick={() => {
                setIsNavExpanded(!isNavExpanded)
              }} to="/contact">Contact Us</NavLink>
            </li>
            {token &&
              <li>
                <NavLink className="nav-item" onClick={() => {
                  setIsNavExpanded(!isNavExpanded)
                }} to="/logout">Log Out</NavLink>
              </li>
            }

            {token &&
              <li>
                <NavLink className="nav-item" onClick={() => {
                  setIsNavExpanded(!isNavExpanded)
                }} to="/edit">Manage Signs</NavLink>
              </li>
            }
            <li>
              <NavLink className="nav-item" onClick={() => {
                setIsNavExpanded(!isNavExpanded)
              }} exact="true" to="/cart">Cart ({totalCartCount})</NavLink>
            </li>
          </ul>
        </div>
      </nav>
      <div className='navbottom navigation'></div>
    </>

  );
}


