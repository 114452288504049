import React, { useContext } from "react";
import './style/Cart.css';
import { Link } from "react-router-dom";
import { SignContext } from "../App";
import config from "../config.js";


const CartBox = () => {

//   const totalCartAmount = context.state.cart
//     .reduce((total, sign) => (total = total + sign.price * sign.count), 0)
//     .toFixed(2);

    const context = useContext(SignContext);

    const totalCartCount = context.state.cart.reduce(
        (total, sign) => (total = total + sign.count),
        0
    );
  
    return (
        <div>
            {/* <h2>
            <Link to="/">Signs</Link> <span>My Cart({totalCartCount})</span>
            </h2> */}

            {/* <h3>Total Cart Amount: &#8378;{totalCartAmount}</h3> */}


            {context.state.cart.map((sign) => (

                <div className="cartItem" key={sign.id}>
                    <Link to={`/signdetails/${sign.id}`}>
                        <div className="imgDiv">
                            <img src={`${config.API_URL}${sign.image_url}`} alt={`${sign.title}`} />
                        </div>
                    </Link>

                    <div className="cartItemRight">
                        <div className="cartItemUpper">
                            <h4>{sign.title}</h4>
                            <div className="cartItemBtn">
                                <p>Quantity: {sign.count}</p>
                                <div>
                                <button onClick={() => context.decrease(sign.id)}>-</button>
                                <button onClick={() => context.removeFromCart(sign.id)}>
                                    Remove
                                </button>
                                <button onClick={() => context.increase(sign.id)}>+</button>
                                </div>
                            </div>
                        </div>
                        <div className="cartItemLower">
                            <div className="cartItemLowerSub">
                                <p>Size: {sign.size}</p>
                                <p>Material: {sign.material}</p>
                            </div>
                            <p>Note: {sign.note}</p>
                        </div>
                    </div>
                </div>

            // <div className="cartItem" key={sign.id}>
            //     <Link to={`/signdetails/${sign.id}`}>
            //         <img src={`${config.API_URL}${sign.image_url}`} alt={`${sign.title}`} />
            //     </Link>
            //   <div>
            //     <Link to={`/signdetails/${sign.id}`}>
            //         <h4>{sign.title}</h4>
            //     </Link>
            //     <p>Size: {sign.size}</p>
            //     <p>Material: {sign.material}</p>
            //     <p>Note: {sign.note}</p>
            //     <p>You have a total of {sign.count} in your cart.</p>
            //     <button onClick={() => context.decrease(sign.id)}>-</button>
            //     <button onClick={() => context.removeFromCart(sign.id)}>
            //       Remove
            //     </button>
            //     <button onClick={() => context.increase(sign.id)}>+</button>
            //   </div>
            // </div>
            ))}

            
            {/* <div className="cartItem">
                <img
                src="https://api.sossafetysigns.com.au/uploads/as01_400x152.png"
                alt="Simyacı"
                />
                <div className="cartItemRight">
                    <div className="cartItemUpper">
                        <h4>Simyaci</h4>
                        <div className="cartItemBtn">
                            <p>Quantity: 10</p>
                            <div>
                                <button>-</button>
                                <button>
                                Remove
                                </button>
                                <button>+</button>
                            </div>
                        </div>
                    </div>
                    <div className="cartItemLower">
                        <div className="cartItemLowerSub">
                            <p>Size: 300x500</p>
                            <p>Material: Vinyl</p>
                        </div>
                        <p>Note: There is no note in this context</p>
                    </div>
                </div>
            </div> */}
        </div>
    );
};

export default CartBox;
